import {
  MoreHorizontal,
  LogoNuxt,
  LogoReact,
  LogoGatsby,
  LogoAngularColor,
  LogoSvelte,
  LogoVue,
  LogoVite,
  LogoRemix,
  LogoNext,
} from 'geist/new-icons/16';
import type { JSX } from 'react';
import { Astro } from '#/app/components/icons/brands/astro';
import { DocusaurusLogo } from '#/app/components/icons/brands/docusaurus';
import { HtmlDarkIcon, HtmlLightIcon } from './framework-icons';

export type Slug =
  | 'ts'
  | 'js'
  | 'tsx'
  | 'jsx'
  | 'nextjs'
  | 'nextjs-app'
  | 'sveltekit'
  | 'nuxt'
  | 'create-react-app'
  | 'gatsby'
  | 'vue'
  | 'vite'
  | 'angular'
  | 'docusaurus'
  | 'remix'
  | 'astro'
  | 'html'
  | 'all'
  | 'other'
  | 'pnpm'
  | 'yarn'
  | 'npm'
  | 'json'
  | 'bun';

export const slugs: Slug[] = [
  'ts',
  'js',
  'tsx',
  'jsx',
  'nextjs',
  'nextjs-app',
  'sveltekit',
  'nuxt',
  'create-react-app',
  'gatsby',
  'vue',
  'vite',
  'angular',
  'docusaurus',
  'remix',
  'astro',
  'html',
  'all',
  'other',
  'pnpm',
  'yarn',
  'npm',
  'json',
  'bun',
];

export interface SwitcherBase {
  name: string;
  slug: Slug;
  altSlugs?: Slug[];
  supportedFeatures?: string[];
  icons: {
    light: JSX.Element;
    dark: JSX.Element;
  };
}

export type Language = Omit<SwitcherBase, 'icons' | 'supportedFeatures'>;

export const languages: Language[] = [
  {
    name: 'TypeScript',
    slug: 'ts',
    // Other slugs that should show
    // under the TypeScript dropdown
    altSlugs: ['tsx'],
  },
  {
    name: 'JavaScript',
    slug: 'js',
    // Other slugs that should show
    // under the JavaScript dropdown
    altSlugs: ['jsx'],
  },
];

export type PackageManager = Omit<
  SwitcherBase,
  'icons' | 'supportedFeatures' | 'altSlugs'
>;

export const packageManagers: PackageManager[] = [
  {
    name: 'pnpm',
    slug: 'pnpm',
  },
  {
    name: 'yarn',
    slug: 'yarn',
  },
  {
    name: 'npm',
    slug: 'npm',
  },
];

export type Framework = Omit<SwitcherBase, 'altSlugs'>;

export const frameworks: Framework[] = [
  {
    name: 'Next.js (/app)',
    slug: 'nextjs-app',
    supportedFeatures: [
      'infrastructure/data-cache',
      'functions',
      'functions/edge-middleware',
      'storage/vercel-kv/kv-reference',
      'storage/vercel-postgres/using-an-orm',
      'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-localhost',
      'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-production',
      'workflow-collaboration/feature-flags/quickstart',
      'workflow-collaboration/feature-flags/supporting-feature-flags',
      'workflow-collaboration/feature-flags/vercel-flags',
      'workflow-collaboration/feature-flags/integrate-with-runtime-logs',
      'edge-middleware/middleware-api',
      'edge-middleware/quickstart',
      'storage/edge-config/edge-config-sdk',
      'analytics/custom-events',
      'analytics/redacting-sensitive-data',
      'projects/overview',
      'functions/configuring-functions',
      'projects/overview',
      'functions/edge-functions',
      'edge-functions/edge-functions-api',
      'storage/edge-config/edge-config-sdk',
      'functions/edge-functions/quickstart',
      'storage/edge-config/integrations',
      'draft-mode',
      'edge-network/caching',
      'analytics/quickstart',
      'analytics/package',
      'audiences/quickstart',
      'speed-insights/quickstart',
      'speed-insights/package',
      'incremental-static-regeneration',
      'frameworks/nextjs',
      'edge-network/redirects',
      'edge-functions/og',
      'vercel-kv/quickstart',
      'vercel-postgres/quickstart',
      'storage/vercel-blob/using-blob-sdk',
      'storage/vercel-postgres/quickstart',
      'otel-overview/quickstart',
      'storage/vercel-blob/server-upload',
      'storage/vercel-blob/client-upload',
      'storage/vercel-blob/quickstart#upload-a-file-using-server-actions',
      'image-optimization/managing-image-optimization-costs',
      'image-optimization/quickstart',
      'observability/log-drains/log-drains-reference',
      'observability/webhooks-overview/webhooks-api',
      'edge-network/headers',
      'functions/streaming/quickstart',
      'functions/quickstart',
      'frameworks/nextjs',
      'cron-jobs/quickstart',
      'cron-jobs/manage-cron-jobs',
      'integrations/ai/replicate',
      'integrations/ai/anyscale',
      'integrations/ai/perplexity',
      'integrations/ai/fal',
      'integrations/ai/modal',
      'recipes/using-local-image',
      'recipes/using-emoji-in-image',
      'recipes/dynamic-text-as-image',
      'recipes/using-an-external-dynamic-image',
      'recipes/using-custom-font',
      'recipes/using-svg-image',
      'recipes/using-tailwind',
      'recipes/using-different-languages',
      'recipes/encrypting-parameters',
      'recipes/streaming-from-llm',
      'recipes/processing-data-chunks',
      'recipes/handling-backpressure',
      'recipes/set-cache-control-headers',
      'recipes/modify-request-headers',
      'recipes/add-response-header',
      'feature-flags/integrate-with-runtime-logs',
      'workflow-collaboration/edit-mode',
      'workflow-collaboration/draft-mode',
    ],
    icons: {
      light: <LogoNext />,
      dark: <LogoNext />,
    },
  },
  {
    name: 'Next.js (/pages)',
    slug: 'nextjs',
    supportedFeatures: [
      'infrastructure/data-cache',
      'functions',
      'functions/streaming/quickstart',
      'functions/quickstart',
      'storage/vercel-kv/kv-reference',
      'storage/vercel-postgres/using-an-orm',
      'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-localhost',
      'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-production',
      'workflow-collaboration/feature-flags/quickstart',
      'workflow-collaboration/feature-flags/supporting-feature-flags',
      'workflow-collaboration/feature-flags/vercel-flags',
      'workflow-collaboration/feature-flags/integrate-with-runtime-logs',
      'projects/overview',
      'draft-mode',
      'storage/edge-config/integrations',
      'functions/edge-middleware',
      'functions/configuring-functions',
      'image-optimization/quickstart',
      'edge-network/caching',
      'incremental-static-regeneration',
      'og-image',
      'analytics/quickstart',
      'analytics/custom-events',
      'analytics/package',
      'analytics/redacting-sensitive-data',
      'speed-insights/quickstart',
      'speed-insights/package',

      'frameworks/nextjs',
      'cron-jobs/quickstart',
      'cron-jobs/manage-cron-jobs',
      'edge-config-sdk',
      'edge-network/redirects',
      'storage/vercel-blob/server-upload',
      'storage/vercel-blob/client-upload',
      'storage/vercel-blob/using-blob-sdk',
      'vercel-kv/quickstart',
      'storage/vercel-postgres/quickstart',
      'otel-overview/quickstart',
      'observability/log-drains/log-drains-reference',
      'observability/webhooks-overview/webhooks-api',
      'edge-network/headers',
      'frameworks/nextjs',
      'integrations/ai/replicate',
      'integrations/ai/anyscale',
      'integrations/ai/perplexity',
      'integrations/ai/fal',
      'integrations/ai/modal',
      'recipes/using-local-image',
      'recipes/using-emoji-in-image',
      'recipes/dynamic-text-as-image',
      'recipes/using-an-external-dynamic-image',
      'recipes/using-custom-font',
      'recipes/using-svg-image',
      'recipes/using-tailwind',
      'recipes/using-different-languages',
      'recipes/encrypting-parameters',
      'recipes/streaming-from-llm',
      'recipes/processing-data-chunks',
      'recipes/handling-backpressure',
      'recipes/set-cache-control-headers',
      'recipes/modify-request-headers',
      'recipes/add-response-header',
      'workflow-collaboration/edit-mode',
      'workflow-collaboration/draft-mode',
    ],
    icons: {
      light: <LogoNext />,
      dark: <LogoNext />,
    },
  },
  {
    name: 'SvelteKit',
    slug: 'sveltekit',
    supportedFeatures: [
      'projects/overview',
      'analytics/quickstart',
      'analytics/custom-events',
      'speed-insights/quickstart',
      'speed-insights/package',

      'edge-network/redirects',
      'image-optimization/quickstart',
      'incremental-static-regeneration/quickstart',
      'functions/configuring-functions/duration',
      'integrations/ai/replicate',
      'integrations/ai/anyscale',
      'integrations/ai/perplexity',
      'integrations/ai/fal',
      'integrations/ai/modal',
      'workflow-collaboration/edit-mode',
      'workflow-collaboration/draft-mode',
      'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-localhost',
      'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-production',
    ],
    icons: {
      light: <LogoSvelte />,
      dark: <LogoSvelte />,
    },
  },
  {
    name: 'Create React App',
    slug: 'create-react-app',
    supportedFeatures: ['speed-insights/quickstart'],
    icons: {
      light: <LogoReact />,
      dark: <LogoReact />,
    },
  },
  {
    name: 'Nuxt',
    slug: 'nuxt',
    supportedFeatures: [
      'analytics/quickstart',
      'analytics/custom-events',
      'speed-insights/quickstart',
      'speed-insights/package',

      'edge-network/redirects',
      'incremental-static-regeneration/quickstart',
      'image-optimization/quickstart',
      'functions/configuring-functions/duration',
    ],
    icons: {
      light: <LogoNuxt />,
      dark: <LogoNuxt />,
    },
  },
  {
    name: 'Gatsby',
    slug: 'gatsby',
    icons: {
      light: <LogoGatsby />,
      dark: <LogoGatsby />,
    },
  },
  {
    name: 'Vue',
    slug: 'vue',
    supportedFeatures: ['speed-insights/quickstart', 'speed-insights/package'],
    icons: {
      light: <LogoVue />,
      dark: <LogoVue />,
    },
  },
  {
    name: 'Vite',
    slug: 'vite',
    icons: {
      light: <LogoVite />,
      dark: <LogoVite />,
    },
  },
  {
    name: 'Angular',
    slug: 'angular',
    supportedFeatures: [],
    icons: {
      light: <LogoAngularColor />,
      dark: <LogoAngularColor />,
    },
  },
  {
    name: 'Docusaurus',
    slug: 'docusaurus',
    supportedFeatures: [],
    icons: {
      light: <DocusaurusLogo height={16} />,
      dark: <DocusaurusLogo height={16} />,
    },
  },
  {
    name: 'Remix',
    slug: 'remix',
    supportedFeatures: [
      'analytics/quickstart',
      'analytics/custom-events',
      'speed-insights/quickstart',
      'speed-insights/package',
      'functions/configuring-functions/duration',
      'functions/configuring-functions/duration',
    ],
    icons: {
      light: <LogoRemix />,
      dark: <LogoRemix />,
    },
  },
  {
    name: 'Astro',
    slug: 'astro',
    supportedFeatures: [
      'image-optimization/quickstart',

      'analytics/quickstart',
      'speed-insights/quickstart',
      'functions/configuring-functions/duration',
    ],
    icons: {
      light: <Astro height={16} />,
      dark: <Astro height={16} />,
    },
  },
  // {
  //   name: 'Hydrogen',
  //   slug: 'hydrogen',
  //   supportedFeatures: [`functions/configuring-functions/duration`],
  //   icons: {
  //     light: <Minus size="16" />,
  //     dark: <Minus size="16" />,
  //   },
  // },
  {
    name: 'HTML',
    slug: 'html',
    supportedFeatures: [
      'analytics/quickstart',
      'analytics/custom-events',
      'analytics/redacting-sensitive-data',
      'speed-insights/quickstart',
      'speed-insights/package',
    ],
    icons: {
      light: <HtmlLightIcon size="16" />,
      dark: <HtmlDarkIcon size="16" />,
    },
  },
  {
    name: 'Other frameworks',
    slug: 'other',
    supportedFeatures: [
      'functions',
      'functions/streaming/quickstart',
      'functions/quickstart',
      'storage/vercel-kv/kv-reference',
      'storage/vercel-postgres/using-an-orm',
      'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-localhost',
      'workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-production',
      'edge-middleware',
      'functions/runtimes',
      'functions/configuring-functions',
      'edge-network/caching',
      'storage/edge-config/integrations',
      'og-image',
      'analytics/package',
      'analytics/quickstart',
      'analytics/custom-events',
      'analytics/redacting-sensitive-data',
      'speed-insights/quickstart',
      'speed-insights/package',

      'cron-jobs/quickstart',
      'cron-jobs/manage-cron-jobs',
      'edge-config-sdk',
      'edge-network/redirects',
      'vercel-kv/quickstart',
      'storage/vercel-postgres/quickstart',
      'storage/vercel-blob/using-blob-sdk',
      'observability/log-drains/log-drains-reference',
      'observability/webhooks-overview/webhooks-api',
      'edge-network/headers',
      'integrations/ai/replicate',
      'integrations/ai/pinecone',
      'integrations/ai/anyscale',
      'integrations/ai/elevenlabs',
      'integrations/ai/lmnt',
      'integrations/ai/perplexity',
      'integrations/ai/togetherai',
      'integrations/ai/fal',
      'integrations/ai/modal',
      'recipes/using-local-image',
      'recipes/using-emoji-in-image',
      'recipes/dynamic-text-as-image',
      'recipes/using-an-external-dynamic-image',
      'recipes/using-custom-font',
      'recipes/using-svg-image',
      'recipes/using-tailwind',
      'recipes/using-different-languages',
      'recipes/encrypting-parameters',
      'recipes/streaming-from-llm',
      'recipes/processing-data-chunks',
      'recipes/handling-backpressure',
      'recipes/set-cache-control-headers',
      'recipes/modify-request-headers',
      'recipes/add-response-header',
    ],
    icons: {
      light: <MoreHorizontal size="16" />,
      dark: <MoreHorizontal size="16" />,
    },
  },
];
