'use client';
import type { ReactNode, JSX } from 'react';
import { useContext, useMemo, createContext, useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { setCookie, getCookie } from '@pyra/cookie';
import type { Framework, Language, PackageManager } from './data';
import { frameworks, languages, packageManagers } from './data';

interface SwitcherContext {
  framework: Framework;
  toggleFramework: (framework: string) => void;
  language: Language;
  toggleLanguage: (language: string) => void;
  packageManager: PackageManager;
  togglePackageManager: (packageManager: string) => void;
  isFrameworkSupported: (path: string) => boolean;
}

const defaultState = {
  /**
   * defaults to nextjs
   */
  framework: frameworks[0],
  toggleFramework: (): null => null,
  /**
   * defaults to typescript
   */
  language: languages[0],
  toggleLanguage: (): null => null,
  /**
   * defaults to pnpm
   */
  packageManager: packageManagers[0],
  togglePackageManager: (): null => null,
  isFrameworkSupported: (): boolean => false,
};

export const SwitcherContext = createContext<SwitcherContext>(defaultState);

function getFrameworkFromCookie(): Framework {
  const framework = getCookie('selected-framework') as string | undefined;
  return frameworks.find((f) => f.slug === framework) || frameworks[0];
}

function getLanguageFromCookie(): Language {
  const language = getCookie('selected-language') as string | undefined;
  return languages.find((l) => l.name === language) || languages[0];
}

function getPackageManagerFromCookie(): PackageManager {
  const packageManager = getCookie('selected-package-manager') as
    | string
    | undefined;
  return (
    packageManagers.find((p) => p.name === packageManager) || packageManagers[0]
  );
}

export const useSwitcherContext = (): SwitcherContext =>
  useContext(SwitcherContext);

export function SwitcherProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [framework, setFramework] = useState<Framework>(
    getFrameworkFromCookie(),
  );
  const [language, setLanguage] = useState<Language>(getLanguageFromCookie());
  const [packageManager, setPackageManager] = useState<PackageManager>(
    getPackageManagerFromCookie(),
  );
  const pathname = usePathname() as string;
  const toggleFramework = useMemo(() => {
    return (selectedFramework: string) => {
      const foundFramework = frameworks.find(
        (f) => f.slug === selectedFramework,
      );
      if (foundFramework) {
        setCookie('selected-framework', selectedFramework);
        setFramework(foundFramework);
      }
    };
  }, []);

  const toggleLanguage = useMemo(() => {
    return (selectedLanguage: string) => {
      const foundLanguage = languages.find((l) => l.name === selectedLanguage);
      if (foundLanguage) {
        setCookie('selected-language', selectedLanguage);
        setLanguage(foundLanguage);
      }
    };
  }, []);

  const togglePackageManager = useMemo(() => {
    return (selectedPackageManager: string) => {
      const foundPackageManager = packageManagers.find(
        (p) => p.name === selectedPackageManager,
      );
      if (foundPackageManager) {
        setCookie('selected-package-manager', selectedPackageManager);
        setPackageManager(foundPackageManager);
      }
    };
  }, []);

  useEffect(() => {
    // If the user selects a framework from another page, that isnt supported on the page the code block is shown on, we need to reset to nextjs to show the correct code
    const currentFramework = frameworks.find((f) => f.slug === framework.slug);
    if (currentFramework?.supportedFeatures) {
      const currentFeature = currentFramework.supportedFeatures.find((f) =>
        pathname.includes(f),
      );
      if (
        pathname.includes('elevenlabs') ||
        pathname.includes('lmnt') ||
        pathname.includes('togetherai')
      ) {
        toggleFramework('other');
      } else if (!currentFeature) {
        const frameworkSlugsOnPath = supportedFrameworks(pathname);
        if (frameworkSlugsOnPath.includes('nextjs-app')) {
          toggleFramework('nextjs-app');
        } else {
          toggleFramework(frameworkSlugsOnPath[0]);
        }
      }
    }
  }, [framework.slug, pathname, toggleFramework]);

  function isFrameworkSupported(path: string): boolean {
    const currentFramework = frameworks.find((f) => f.slug === framework.slug);
    if (currentFramework?.supportedFeatures) {
      const currentFeature = currentFramework.supportedFeatures.find((f) =>
        path.includes(f),
      );
      if (currentFeature) {
        return true;
      }
    }
    return false;
  }

  function supportedFrameworks(path: string): string[] {
    const frameworksOnPath = frameworks
      .filter((f) => f.supportedFeatures?.find((fpath) => path.includes(fpath)))
      .map((f) => f.slug);
    return frameworksOnPath;
  }

  return (
    <SwitcherContext.Provider
      value={{
        framework,
        toggleFramework,
        language,
        toggleLanguage,
        packageManager,
        togglePackageManager,
        isFrameworkSupported,
      }}
    >
      {children}
    </SwitcherContext.Provider>
  );
}

const defaultId = 'code-block';

export const SwitcherIdContext = createContext<string>(defaultId);

export const useSwitcherIdContext = (): string => useContext(SwitcherIdContext);

/**
 * This Provider allows to have multiple code blocks with switcher in your MDX page.
 * Each code blocks should be wrap in its own SwitcherIdProvider context with a unique (per page) id value.
 *
 * @example
 * // Given 2 mdx files exporting one code block each:
 * import Example1 from 'example1.mdx'
 * import Example2 from 'example2.mdx'
 * import \{ SwitcherIdProvider \} from '\@components/docs/text'
 *
 * <SwitcherIdProvider id="one">
 *  <Example1/>
 * </SwitcherIdProvider>
 *
 * <SwitcherIdProvider id="two">
 *  <Example2/>
 * </SwitcherIdProvider>
 */
export function SwitcherIdProvider({
  children,
  id,
}: {
  children: ReactNode;
  id?: string;
}): JSX.Element {
  return (
    <SwitcherIdContext.Provider value={id ?? defaultId}>
      {children}
    </SwitcherIdContext.Provider>
  );
}
